import React from "react";
import {Card} from "react-bootstrap";

const Article = ({article})  => {

   const find_body_text = Object.values(JSON.parse(article.body).blocks).map(a=>a.text)
   const overview = (find_body_text.join(' ')).substring(0,200)+' ...'
     
  return (
      <Card className="deckStyle bg-light" style={{ border: "none" }}>
          <Card.Body >
 
             <Card.Title className="text-center p-5">{article.title}</Card.Title>
             
             <img className="card-img" src={article.picture} alt={article.title}/>

            {!article?<div/>:<div><p>{overview}</p></div>}
            
             <div className="card-footer">
                <p className="card-text">
                   <small className="text-muted">{article.date} - {article.author}</small>
                </p>
             </div>
       
          </Card.Body>
       </Card>
   

    );
};

export default Article; 
