import React, { useEffect} from "react";
import PropTypes from "prop-types";
import ViewArticle from "../../components/admin/ViewArticle";
import {getArticleByLinkFormat} from "../../actions/articleActions";
import { EditorState, ContentState, convertFromRaw} from 'draft-js';
import {connect} from "react-redux";

const ViewItemPage = ({
   auth,
   article,
   match,
   getArticleByLinkFormat
}) => {
   useEffect(() => {
      getArticleByLinkFormat(match.params.linkFormat)
   }, [match, getArticleByLinkFormat]);

   const len_articles = [...Array(Object.keys(article).length).keys()]
   const view_article = len_articles.map(a=>Object.values(article)[a])
   const article_text = !view_article[4]?EditorState.createWithContent(ContentState.createFromText('Article')):EditorState.createWithContent(convertFromRaw(JSON.parse(view_article[4])))
   
   if (Object.keys(article).length === 0) return <div />;
   return (

         <ViewArticle
            article={article}
            auth={auth}
            editorState={article_text}
         />

   );
};
const mapStateToProps = state => ({
   article: state.article.article,
   auth:state.auth.isAuthenticated
});
ViewItemPage.propTypes = {
   auth:PropTypes.bool.isRequired,
   article: PropTypes.object.isRequired,
   getArticleByLinkFormat: PropTypes.func.isRequired
};

export default connect(
   mapStateToProps,
   {getArticleByLinkFormat}
)(ViewItemPage);