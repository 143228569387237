import React from "react";
import Item from "../boutique/Item";
import PropTypes from "prop-types";
// import "../blog/article.scss";

const ListItem = ({items,prices,onClick}) => {
const isUndefined = Object.values(items)[0]===undefined ||Object.values(prices)[0]===undefined
   return (

         <div className="grid-container-blog">
            {!items || isUndefined? <div/>
            :
            Object.values(items)[0].map(item => (
               Object.values(prices)[0].filter(a=>a.product ===item.id).map(b =>
                  <Item item={item} price={b} onClick={onClick} />)
            ))}           
         </div>
      
   );
};

ListItem.propTypes = {
   articles: PropTypes.array.isRequired
};

export default ListItem;

// <Link to={`/boutique/${items.name}`} key={items.id}>
// <Item item={item}/>
// </Link>