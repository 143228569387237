import React from "react";

const LegalMention = ()  => {
    return (
      <div>
        <header id="head">
            <div className="container">
                <div className="row">
                    <h1 className="lead">Capecassia</h1>

                </div>
            </div>
        </header>
        <div className="container" style={{textAlign:"left"}}>
     
            <br/>
            <h2 className="thin">Terms and Conditions</h2>

            Please read the different terms of use of this site carefully before browsing its pages.
            By connecting to this site, you fully accept these terms. 
            Also, in accordance with article n ° 6 of Law n ° 2004-575 of June 21, 2004 on confidence in the digital economy, 
            the managers of this website  <a href="http://www.capecassia.com">www.capecassia.com</a> are :
            <br/><br/>

            <p style={{color: "#b51a00;"}}><span style={{color: "rgb(0, 0, 0);"}}><b>Website editor : </b></span></p>
            
            <ul>
            <li>Helen Tomalin</li>
            {/* <li>SIRET number :  00000000</li> */}
            <li>Editorial manager : Helen Tomalin</li>
            {/* <li>Address</li>
            <li>Phone number :0125365 - Fax : 000000</li> */}
            <li>Email : hello@capecassia.com</li>
            <li>Website : <a href="http://www.capecassia.com">www.capecassia.com</a></li>
            </ul>
           
            <p style={{color: "#b51a00;"}}><b><span style={{color: "rgb(0, 0, 0);"}}>Hoster :</span> </b></p>
            Host : heroku
            415 Mission Street, Suite 300 San Francisco, CA 94105
            Website :  <a href="http://www.heroku.com">www.heroku.com</a>
            <br/><br/>

            <p style={{color: "#b51a00;"}}><span style={{color: "rgb(0, 0, 0);"}}><b>Development</b><b> : </b></span></p>
            Nadia Abdallah
            Adresse : Malakoff
            Site Web : <a href="http://www.nadia-abdallah.dev">www.nadia-abdallah.dev</a>
            <br/><br/>
            <p style={{color: "#b51a00;"}}><span style={{color: "rgb(0, 0, 0);"}}><b>Terms of use : </b></span></p>
            This website(<a href="http://www.capecassia.com">www.capecassia.com</a>) is available in different web languages ​​(HTML, HTML5, Javascript, CSS, etc ...)
            for better user comfort and more pleasant graphics, we recommend that you use browsers
            modern ones like Internet Explorer, Safari, Firefox, Google Chrome, etc ...

            {/* <span style={{color: "#323333;"}}>Helen Tomalin<b> </b></span>met en œuvre tous les moyens dont elle dispose, pour assurer une information fiable et une mise à jour fiable de ses sites internet. Toutefois, des erreurs ou omissions peuvent survenir. L'internaute devra donc s'assurer de l'exactitude des informations auprès de , et signaler toutes modifications du site qu'il jugerait utile. n'est en aucun cas responsable de l'utilisation faite de ces informations, et de tout préjudice direct ou indirect pouvant en découler. */}

            <br/><br/>
            <b>Cookies</b> :  <br/><br/>This website <a href="http://www.capecassia.com">www.capecassia.com</a> uses all the means at its disposal to ensure reliable information and reliable updating of its websites. However, errors or omissions may occur. The user must therefore ensure the accuracy of the information with, and report any changes to the site that he deems useful. is in no way responsible for the use made of this information, and for any direct or indirect damage that may result from it.
            
            <br/><br/>
            <b> Hypertext links: </b>  <br/><br/>The websites of may provide links to other websites or other resources available on the Internet. Helen Tomalin has no way of controlling sites in connection with her websites. does not answer for the availability of such sites and external sources, nor does it guarantee it. It cannot be held responsible for any damage of any nature whatsoever resulting from the content of these sites or external sources, and in particular from the information, products or services they offer, or from any use that may be made of these elements. The risks associated with this use are the sole responsibility of the Internet user, who must comply with their conditions of use.

            Users, subscribers and visitors to websites cannot set up a hyperlink to this site without the express prior authorization of Helen Tomalin.

            In the event that a user or visitor wishes to set up a hyperlink to one of Helen Tomalin's websites, it will be up to him to send an email accessible on the site in order to formulate his request for setting up a hyperlink. Helen Tomalin reserves the right to accept or refuse a hyperlink without having to justify its decision.
           <br/>

           <br/><br/>
            <p style = {{color: "# b51a00;"}}> <span style = {{color: "rgb (0, 0, 0);"}}> <b> Services provided: </b> </ span> </p>
            <p style = {{color: "# 323333;"}}> All of the company's activities as well as its information are presented on our website <a href="http://www.capecassia.com"> www .capecassia.com </a>. </p>
            <p style = {{color: "# 323333;"}}> Helen Tomalin strives to provide the site www.capecassia.com with as accurate information as possible. the information on the site <a href="http://www.capecassia.com"> www.capecassia.com </a> is not exhaustive and the photos are not contractual. They are given subject to modifications having been made since they were put online. In addition, all the information indicated
            on the site www.capecassia.com <span style = {{color: "# 000000;"}}> <b> </b> </span> are given for information only, and are liable to change or evolve without notice. </p>
           <br/>
           <p style = {{color: "# b51a00;"}}> <span style = {{color: "rgb (0, 0, 0);"}}> <b> Contractual limitation on data: </ b > </span> </p>
            The information on this site is as accurate as possible and the site is updated at different times of the year, but may contain inaccuracies or omissions. If you notice a gap, error or what appears to be a malfunction, please report it by email to the address hello@capecassia.com, describing the problem as precisely as possible (page with the problem, type of computer and browser used, etc.).

            All downloaded content is done at the user's own risk and under his sole responsibility. Consequently, cannot be held responsible for any damage suffered by the user's computer or any loss of data resulting from the download. <span style = {{color: "# 323333;"}}> In addition, the user of the site agrees to access the site using recent equipment, not containing viruses and with a latest generation browser installed -update </span>

            The hypertext links set up within the framework of this website to other resources present on the Internet network cannot engage the responsibility of Helen Tomalin.
           <br/>
           <p style = {{color: "# b51a00;"}}> <span style = {{color: "rgb (0, 0, 0);"}}>  <br/><br/><b> Intellectual property: </b> </ span> </p>
            All the content of this present on the <a href="http://www.capecassia.com"> www.capecassia.com </a> site, including, without limitation, graphics, images, texts, videos, animations, sounds, logos, gifs and icons as well as their formatting are the exclusive property of the company with the exception of trademarks, logos or content belonging to other partner companies or authors.

            Any reproduction, distribution, modification, adaptation, retransmission or publication, even partial, of these different elements is strictly prohibited without the express written consent of Helen Tomalin. This representation or reproduction, by any means whatsoever, constitutes an infringement punishable by articles L.335-2 and following of the Code of intellectual property. Failure to comply with this prohibition constitutes an infringement which may engage the civil and criminal liability of the infringer. In addition, the owners of the copied Content could take legal action against you.
           <br/>
            <p style = {{color: "# b51a00;"}}> <span style = {{color: "rgb (0, 0, 0);"}}>  <br/><br/><b> Declaration to the CNIL: </b> </span> </p>
            In accordance with Law 78-17 of January 6, 1978 (amended by Law 2004-801 of August 6, 2004 relating to the protection of individuals with regard to the processing of personal data) relating to computers, files and freedoms, this site has not been declared to the National Commission for Information Technology and Freedoms (<a href="http://www.cnil.fr/"> www. cnil.fr </a>).
           <br/>
           <p style = {{color: "# b51a00;"}}> <span style = {{color: "rgb (0, 0, 0);"}}> <br/><br/> <b> Disputes: </b> </ span > </p>
            The present conditions of the <a href="http://www.capecassia.com"> www.capecassia.com </a> site are governed by French law and any dispute or litigation that may arise from the interpretation or the execution of these will be the exclusive competence of the courts on which the head office of the company depends. The reference language, for the settlement of possible disputes, is French.
           <br/>
            <p style = {{color: "# b51a00;"}}> <span style = {{color: "rgb (0, 0, 0);"}}>  <br/><br/> <b> Personal data: </b> </ span> </p>
            In general, you are not required to provide us with your personal data when you visit our website <a href="http://www.capecassia.com"> www.capecassia.com </a>.However, this principle has certain exceptions. In fact, for certain services offered by our site, you may be required to provide us with certain data such as: your name, your function, the name of your company, your e-mail address, and your telephone number. This is the case when you complete the form that is offered to you online, in the "contact" section. In any case, you can refuse to provide your personal data. In this case, you will not be able to use the services of the site, in particular that of soliciting information about our company, or of receiving newsletters.

            Finally, we may automatically collect certain information about you when simply browsing our website, in particular: information about the use of our site, such as the areas you visit and the services you access, your address IP, your browser type, your access times. Such information is used exclusively for internal statistical purposes, so as to improve the quality of the services offered to you. The databases are protected by the provisions of the law of July 1, 1998 transposing Directive 96/9 of March 11, 1996 on the legal protection of databases.         

	    </div>
 
    </div>
   
    );
};

export default LegalMention;


