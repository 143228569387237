import axios from "axios";

import {
   CHECKOUT_SESSION,
   STRIPE_LIST_PAINT,
   STRIPE_LIST_PRICES
} from "./types";

import { setErrors } from "./errorActions";

const stripe = window.Stripe(process.env.REACT_APP_STRIPE_KEY)
//stripe get all paints
export const getAllPaints = () => dispatch => {
  axios
  .get(`/api/stripeAPI/listPaint`)
  .then(res => {
   
    dispatch({
     type: STRIPE_LIST_PAINT,
     payload: res.data
  });
  })
  .catch(err => {
    dispatch(setErrors(err));
  });
}

//stripe get all prices
export const getAllPrices = () => dispatch => {
  axios
  .get(`/api/stripeAPI/listPrices`)
  .then(res => {
    dispatch({
     type: STRIPE_LIST_PRICES,
     payload: res.data
  });
  })
  .catch(err => {
    dispatch(setErrors(err))
  });
}

//stripe checkouts
export const getCheckout = (data) => dispatch => {

  axios
  .post(`/api/stripeAPI/checkout`,data)
  .then(res => {
    stripe.redirectToCheckout({
      sessionId: res.data.id
    });
    dispatch({
     type: CHECKOUT_SESSION,
     payload: res.data
  });
  })
  .catch(err => {
    dispatch(setErrors(err));
  });
}


//stripe events 
export const getEvents = () => dispatch => {
  axios
  .post(`/api/stripeAPI/listEvents`)
  .then(res => {
  })
  .catch(err => {
    dispatch(setErrors(err));

  });
}