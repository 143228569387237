import React from "react";
import { connect } from "react-redux";

import Header from "../../components/layout/Header";


const HeaderPage = ({

})  => {


  return (
    
      <Header
        
      />
    

  );
};


export default connect()(HeaderPage);