import React from "react";
import ListItem from "../boutique/ListItem";
import PropTypes from "prop-types";
// import "./blogArticle.scss";

const BoutiqueListItems = ({items,prices,onClick}) => {


   return (
      <div>
          <header id="head" className="secondary"></header>
            <div className="container">
                     <header className="page-header">
                           <h1 className="page-title"> Boutique </h1>
                     </header>
                     <div className="row">
                        <div className="col-lg-12 pb-5">
                              <ListItem
                                 items={items}
                                 prices={prices}
                                 onClick={onClick}

                              />
                        </div>
                     </div>
            </div>
      </div>
   );
};

BoutiqueListItems.propTypes = {
   articles: PropTypes.array.isRequired
};

export default BoutiqueListItems;