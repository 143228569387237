import { SET_CURRENT_ADMIN, TOGGLE_ADMIN_LOADING,UPDATE_CLIENT,GET_ADMIN_INFO} from "../actions/types";
const isEmpty = require("is-empty");

const initialState = {
   isAuthenticated: false,
   clientObj: {},
   admin_info:{},
   updateClient:{},
   adminLoading: false
};

export default function(state = initialState, action) {
   switch (action.type) {
      case GET_ADMIN_INFO:
         return {
            ...state,
            admin_info:{...action.payload.info[0]}
         };
      case SET_CURRENT_ADMIN:
         return {
            ...state,
            isAuthenticated: !isEmpty(action.payload),
            clientObj: action.payload
         };
      case UPDATE_CLIENT:
         return {
            ...state,
            updateClient: action.payload
         };
      case TOGGLE_ADMIN_LOADING:
         return {
            ...state,
            adminLoading: !state.adminLoading
         };
      default:
         return state;
   }
}