import React from "react";

const Footer = ()  => {
  return (
    <footer id="footer" className="top-space">

      <div className="footer1">
        <div className="container">
          <div className="row">
            <div className="col-md-4 widget">
              <h3 className="widget-title">Contact</h3>
              <div className="widget-body">
                <p>
                  <a href="mailto:hello@capecassia.com">hello@capecassia.com</a><br/>
                  <br/>
                </p>	
              </div>
            </div>

            <div className="col-md-6 widget">
              <h3 className="widget-title">Follow me</h3>
              <div className="widget-body">
                <p className="follow-me-icons">

                  <a href="https://pin.it/5lIcxej"><i className="fa fa-pinterest fa-2"></i></a>
                  <a href="https://www.instagram.com/agentfoxterrier007/"><i className="fa fa-instagram fa-2"></i></a>
                </p>	
              </div>
            </div>

            <div className="col-md-2 widget">
              <h3 className="widget-title">Capecassia </h3>
              <div className="widget-body">
                <a href="/legalmentions">Legal notice</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <div className="row">
            
            <div className="col-md-6 widget">
              <div className="widget-body">
              </div>
            </div>

            <div className="col-md-6 widget">
              <div className="widget-body">
                <p className="text-right">
                  Copyright &copy; 2021 Capecassia Designed by <a href="https://www.nadia-abdallah.dev/" rel="designer">nadia-abdallah.dev</a> 
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>

    </footer>	
  );
};

export default Footer;