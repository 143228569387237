import React from "react";
import Gap from "../layout/Gap";

const Header = ({introduction,editorState})  => {
    return (
      <div>
          <Gap/>

        
        <header id="head">
        </header>

    </div>
   
    );
};

export default Header;


