import React from "react";
import {Container, Row} from "react-bootstrap";
import Gap from "../layout/Gap";
import Input from "../form/Input";

const Settings = ({onChange,onSubmit,profil,actual_info,onBlur}) => {
    const {password, errors } = profil;

    function validate(params) {
        const check_email = Object.values(errors)[0].length
        const check_password=Object.values(errors)[1].length
        const final_check=check_email ===0 && check_password===0

        return final_check
    }

   return (
    <Container>
        <Row>
            <Gap/><Gap/>

            <div className="col-lg-12 pb-5">
                <header className="page-header">
                    <h1 className="page-title">Settings</h1>
                </header>
                <h3><span>⚒️ </span> This page is dedicated to update your settings : username, email and password</h3>
                <br/> <br/>
            </div>

            <Input
                name="username"
                type="text"
                placeholder={actual_info.user_name}
                onChange={onChange}
                onBlur={onBlur}
                text={{
                module: "settings",
                label: "Username",
                error: errors.username
                }}
            />

            <Input
                name="email"
                type="text"
                placeholder={actual_info.email}
                onChange={onChange}
                onBlur={onBlur}
                text={{
                module: "settings",
                label: "Email",
                error: errors.email
                }}
            />

            <Input
                name="password"
                type="text"
                placeholder={password}
                onChange={onChange}
                onBlur={onBlur}
                text={{
                module: "settings",
                label: "Password",
                error: errors.password
                }}
            />

            {validate(errors)?
            
            <button 
                onClick={onSubmit}
                className="button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon"
            >
                    <span className="button__rectangle__button--default__icon">
                    ✔️
                    </span>
                    Validate
            </button>:
            <button 
                onClick={onSubmit}
                className="button__rectangle__button button__rectangle__button--default button__rectangle__button--default--primary--icon"
                style={{backgroundColor:"tomato"}}
            >
                <span className="button__rectangle__button--default__icon">
                ⚠️
                </span>
                Error
            </button>
                
                }

        </Row>
    </Container>
      
   );
};


export default Settings;