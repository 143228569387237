import React from "react";
import {Button,Card} from "react-bootstrap";
import {currencyFormat} from "./../../utils/dashboardFunction";

const Item = ({item,price,onClick})  => {
  return (
      <Card className="deckStyle bg-light" style={{ border: "none" }}>
          <Card.Body >
 
             <Card.Title className="text-center p-5">{item.name}</Card.Title>
         
             
             <img className="card-img" src={item.images} alt={item.name}/>

             <br/><br/>
         

            {!item?<div/>:<div><p>{item.description}</p></div>}

            Price : {currencyFormat(price.amount)}

          </Card.Body>

            <div className="card-footer">
                <p className="card-text">
                  <Button onClick={e=>onClick(e,item,price)} id={price.id} variant="light" className="styleBtn">
                     Buy
                  </Button>
                </p>
             </div>
       </Card>
    );
};

export default Item; 
