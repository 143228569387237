import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ArticleForm from "../../components/blog/ArticleForm";
import Validate from "../../components/form/Validate";
import { connect } from "react-redux";
import isEmpty from "is-empty";
import { getArticleByID, updateArticle,uploadImage,getImagesCloudinary } from "../../actions/articleActions";
import { EditorState,convertToRaw, ContentState,convertFromRaw} from 'draft-js';

const UpdateArticlePage = ({
   errors,updateArticle,
   uploadImage, loading,
   currentArticle,imgLoading,
   article_categories,
   history,imageUpload,getImagesCloudinary
}) => {

   const [article, setArticle] = useState({
      category:currentArticle.category,
      title: currentArticle.title,
      body: currentArticle.body,
      picture:currentArticle.picture,
      errors: {}
   });

   useEffect(()=>{
      setArticle(article => {
         return { ...article, errors };
      });
      getImagesCloudinary();
      isEmpty(imageUpload)?<div/>:setArticle(article => {return {...article,picture:Object.values(imageUpload)[15]}});
  
   },[errors,imageUpload,getImagesCloudinary])
   
   const [boolTitle,setBoolTitle]=useState(false)
   const [boolPic,setBoolPic]=useState(false)
   const steps = document.querySelectorAll('.stepper__row');
   const stepsArray = Array.from(steps);

   function clickHandler(target) {
      const currentStep = document.querySelector('.stepper__row--active');
      stateHandler(currentStep);
   }

   function stateHandler(step) {
   let nextStep;
   let currentStepIndex = stepsArray.indexOf(step);
   if (currentStepIndex < stepsArray.length - 1) {
      nextStep = stepsArray[currentStepIndex + 1];
      classHandler([nextStep, step])
   }}

   function classHandler(steps) {
      steps.forEach(step => {
         step.classList.toggle('stepper__row--disabled');
         step.classList.toggle('stepper__row--active');
      });
   
   }

   function boolTitleFunction(){
      clickHandler()
      setBoolTitle(!boolTitle)
   }

   const len_articles = !currentArticle.body?[0]:[...Array(Object.keys(currentArticle).length).keys()]
   const view_article = len_articles.map(a=>Object.values(currentArticle)[a])
   const article_text = !view_article[4]?EditorState.createWithContent(ContentState.createFromText('Article')):EditorState.createWithContent(convertFromRaw(JSON.parse(view_article[4])))

   const [editorState, setEditorState] = useState(article_text)
   const [previewSource, setPreviewSource] = useState('')
   const [selectedFile,setSelectedFile]=useState('')

   const handleChange = e => {
      switch (e.target) {
         case undefined:
            setArticle({...article,body: JSON.stringify(convertToRaw(editorState.getCurrentContent()))});
            break;
      
         default:
            switch (e.target.name) {
               case 'picture':
                  const file = e.target.files[0]
                  previewFile(file)
                  setSelectedFile(file)                    
                  break;
               case'body':
               setArticle({
                  ...article,
                  [e.target.name]: e.target.value
               })
                  break;
               default:
                  setArticle({
                     ...article,
                     [e.target.name]: e.target.value
                  })
                  break;
            }
            break;
      }
   };
   const handleChangeContent = e =>{
      setEditorState(e);
   }
 
   const handleBlur = e => {
      const { name, value } = e.target;
      const error = { ...article.errors, ...Validate(name, value).errors };
      setArticle({ ...article, errors: { ...error } });
   };

   const previewFile = (file) => {
      const reader = new FileReader();
      let pic_size;

      reader.readAsDataURL(file);
      reader.onloadend = () => {
          setPreviewSource(reader.result);
      };

      Math.round(file.size/1000)>=2000 ?pic_size="Please upload a file smaller than 2 Mo":pic_size=""
      setArticle({...article,errors:{pic_size}})

   };

   const handleValidPicture = e => {
      e.preventDefault();
      switch (!selectedFile) {
         case false:
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onloadend = () => {
                uploadImage(reader.result)
               };
            break;
      
         default:
            break;
      }
    
      clickHandler()
      setBoolPic(!boolPic)
   };

   // to ensure that the post is loaded otherwise we would make uncontrolled form access error
   const isArticleLoaded = () => {
      return article.title || article.body || Object.keys(article.errors).length > 0;
   };

   const handleSubmit = e => {
      e.preventDefault();
      const{ category, picture,title,body } = article;
      const id = currentArticle._id
      
      updateArticle({ id,category, picture,title,body }, history);
   };

   return isArticleLoaded() ? (
      <ArticleForm
         loading={loading}
         imgLoading={imgLoading}
         article={article}
         onChange={handleChange}
         onBlur={handleBlur}

         onSubmit = {handleSubmit}
         editorState={editorState}
         list_cat={article_categories}
         onEditorStateChange={handleChangeContent}
         previewPicture={previewSource}
         titleForm={"Update an article"}
         isUpdate={true}
         isIntro={false}
         isNew={false}
         onClickHandler={clickHandler}

         //booleen for input
         boolTitle={boolTitle}
         boolTitleFunction={boolTitleFunction}
         boolPic={boolPic}
         boolPicFunction={handleValidPicture}

         //booleen check if picture is loaded in cloudinary
         picLoaded={imageUpload}
      />
   ) : (
      <div />
   );
};

const mapStateToProps = state => ({
   currentArticle: state.article.article,
   loading: state.article.articleLoading,
   article_categories:state.article.article_categories,
   imgLoading:state.article.imgLoading,
   errors: state.errors,
   img_cloudinary:state.article.img_cloudinary,
   imageUpload:state.article.imageUpload
});

UpdateArticlePage.propTypes = {
   currentArticle: PropTypes.object.isRequired,
   loading: PropTypes.bool.isRequired,
   errors: PropTypes.object.isRequired,
   getArticleByID: PropTypes.func.isRequired,
   updateArticle: PropTypes.func.isRequired,
   uploadImage:PropTypes.func.isRequired,
   getImagesCloudinary:PropTypes.func.isRequired
};

export default connect(
   mapStateToProps,
   {getArticleByID, updateArticle,uploadImage,getImagesCloudinary}
)(UpdateArticlePage);