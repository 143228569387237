import { SET_REGISTER_USER } from "../actions/types";

const initialState = {
   user: {},
};

export default function(state = initialState, action) {
   switch (action.type) {
      case SET_REGISTER_USER:
         return {
            ...state,
            user: action.payload
         }; 

      default:
         return state;
   }
}