import React, { useEffect,useState } from "react";
import Settings from "../../components/admin/Settings";
import {getAdminInfo,updateAdminInfo} from "../../actions/authActions";
import isEmpty from "is-empty";
import Validate from "../../components/form/Validate";

import { connect } from "react-redux";
import PropTypes from "prop-types";


const SettingsPage = ({
    clientObj,

    getAdminInfo,
    admin_info,
    updateAdminInfo
}) => {

let update_personnal_info=[]

const [profil, setProfil] = useState({
    email:"",
    username:"",
    password:"",
    errors: {email:"",password:""}
});

useEffect(() => {
  getAdminInfo(clientObj.id)
}, [])

const handleChange = e => {
    switch (e.target.name) {
        case "email":
        setProfil({...profil,
            email:e.target.value
        })
        break;

        case "username":
            setProfil({...profil,
                username:e.target.value
        })
    
        default:
            setProfil((profil)=>({ ...profil,
                [e.target.name]:e.target.value
            }))
            break;
    }
  
 };

 const handleBlur = e => {
    const {name, value} = e.target;

    const error = { ...profil.errors, ...Validate(name, value).errors };
    setProfil({ ...profil, errors: { ...error } });
}



const handleSubmit = e => {
    update_personnal_info = [
        {email:profil.email},
        {user_name:profil.username},
        {password:profil.password}]

    for (let index = 0; index < update_personnal_info.length; index++) {
        const element = update_personnal_info[index];

        if (!isEmpty(Object.values(element)[0])){
            updateAdminInfo({elmnt:Object.keys(element)[0],value:Object.values(element)[0]})
        }
        
    }

};
   return (
      <Settings
        onChange={handleChange}
        onSubmit={handleSubmit}
        onBlur={handleBlur}
        profil={profil}
        actual_info={admin_info}
      />
   
   );
};

const mapStateToProps = state => ({

    clientObj:state.auth.clientObj,
    admin_info:state.auth.admin_info
});

SettingsPage.propTypes = {

    clientObj:PropTypes.array.isRequired,
    admin_info:PropTypes.array.isRequired,
    getAdminInfo:PropTypes.func.isRequired,
    updateAdminInfo:PropTypes.func.isRequired

};

export default connect(
   mapStateToProps,
   {getAdminInfo,updateAdminInfo}
)(SettingsPage);