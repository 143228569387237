import {
    CREATE_ARTICLE,
    UPLOAD_IMAGE,
    GET_IMG_CLOUDINARY,
    GET_ARTICLE,
    GET_ARTICLES,
    UPDATE_ARTICLE,
    DELETE_ARTICLE,
    TOGGLE_ARTICLES_LOADING,
    TOGGLE_ARTICLE_LOADING,
    RESET_ARTICLE,
    DELETE_IMG,
    TOGGLE_IMG_LOADING
 } from "../actions/types";
 
 const initialState = {
   article: {},
   articles: [],
   imageUpload:{},
   img_cloudinary:{result:[{public_id : 0,url: 0,file_name:0}]},
   articleLoading: false,
   articlesLoading: false,
   imgLoading:false,
   delete_img:{},
   article_categories: ["Fashion","Art","Lifestyle","Travel","About me"]
 };
 
 export default function(state = initialState, action) {
    switch (action.type) {
       case CREATE_ARTICLE:
          return {
             ...state,
             articles: [...state.articles, action.payload]
          };

      case UPLOAD_IMAGE:
         return {
            ...state,
            imageUpload:{ ...action.payload }
         }

      case GET_IMG_CLOUDINARY:
         return {
            ...state,
            img_cloudinary:{...action.payload}
         }
      case GET_ARTICLES:
         return {
            ...state,
            article: {},
            articles: [...action.payload]
         };
      case GET_ARTICLE:
         return {
            ...state,
            article: { ...action.payload[0] }
         };
       case UPDATE_ARTICLE:
          const articles = state.articles.filter(
             article => article._id !== action.payload._id
          );
          return {
             ...state,
             article: {},
             articles: [...articles, action.payload]
          };
       case DELETE_ARTICLE:
          return {
             ...state,
             articles: state.articles.filter(article => article._id !== action.payload)
          };

      case DELETE_IMG:
         return {
            ...state,
            delete_img:action.payload
         }
       case TOGGLE_ARTICLE_LOADING:
          return {
             ...state,
             articleLoading: !state.articleLoading
          };
      case TOGGLE_ARTICLES_LOADING:
         return {
            ...state,
            articlesLoading: !state.articlesLoading
         };
      case TOGGLE_IMG_LOADING:
            return {
               ...state,
               imgLoading: !state.imgLoading
            };
       case RESET_ARTICLE:
          return initialState;
       default:
          return state;
    }
 }