import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import { SET_CURRENT_ADMIN, TOGGLE_ADMIN_LOADING,UPDATE_ADMIN_PROFIL,GET_ADMIN_INFO } from "./types";

import { setErrors } from "./errorActions";

export const registerAdmin = (clientData, history) => dispatch => {
   dispatch(toggleAdminLoading());
   axios
      .post("/api/users/signup", clientData)
      .then(res => {
         dispatch(toggleAdminLoading());
         localStorage.setItem(
            "loginMessage",
            "Inscription validée"
         );
         history.push("/login");
      })
      .catch(err => {
         dispatch(setErrors(err.response.data));
         dispatch(toggleAdminLoading());
      });
};

export const loginClient = clientData => dispatch => {
   dispatch(toggleAdminLoading());
   axios
      .post("/api/users/login", clientData)
      .then(res => {
      
         const { token } = res.data;
         localStorage.setItem("jwtToken", token);
         setAuthToken(token);
         const decoded = jwt_decode(token);
         dispatch(setCurrentAdmin(decoded));
         dispatch(toggleAdminLoading());
      })
      .catch(err => {
         dispatch(setErrors(err));
         dispatch(toggleAdminLoading());
      });
};

export const updateAdminInfo = (data)=>dispatch => {
   axios
   .post(`/api/users/info/update`,{data:data})
      .then(res => {
         dispatch({
            type: UPDATE_ADMIN_PROFIL,
            payload: res.data
         });
         window.location.reload();
      })
      .catch(err => {
         dispatch(setErrors(err.response.data));
      });
}


export const getAdminInfo = (id)=> dispatch => {
   axios
   .post(`/api/users/info`,{data:id})
      .then(res => {
         dispatch({
            type: GET_ADMIN_INFO,
            payload: res.data
         });
      })
      .catch(err => {
        dispatch(setErrors(err.response.data));
      });

}


export const setCurrentAdmin = clientData => {
   return {
      type: SET_CURRENT_ADMIN,
      payload: clientData
   };
};

export const toggleAdminLoading = () => {
   return {
      type: TOGGLE_ADMIN_LOADING
   };
};

export const logoutAdmin = () => dispatch => {
   localStorage.removeItem("jwtToken");
   setAuthToken(false);
   dispatch(setCurrentAdmin({}));
   window.location.reload();
};