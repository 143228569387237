import React from "react";


const Gap = ()  => {

  return (
    <div className="container">
        <div className="row">
            <div className="col-lg-6">
            <div className="text-container">
                        <h1><span className="black">{""}</span></h1>
            </div>
            </div>
            <div className="col-lg-6">
            <div className="text-container">
                        <h1><span className="black">{""}</span></h1>
                        <br></br>
                        <br></br>
            </div>
            </div>
        </div>
    </div>
    
  );
};

export default Gap;