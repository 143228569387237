import React from "react";
import HeaderPage from "../../containers/layout/HeaderPage";


const Home = () => (
    <div className="master-wrapper">

        <HeaderPage/>

    </div>
);

export default Home;