import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

ReactDOM.render(
<App />, 
  document.getElementById('root')
  );


// console.log('Stripe key is',process.env.REACT_APP_STRIPE_KEY)
// console.log('Environment is',process.env.NODE_ENV)