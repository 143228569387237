import React from "react";

const NavigationBar = ({onClick,auth})  => {

  return (
    <div className="navbar navbar-inverse navbar-fixed-top headroom" >
        <div className="container">
            <div className="navbar-header">
                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse"><span className="icon-bar"></span> <span className="icon-bar"></span> <span className="icon-bar"></span> </button>
                <a className="navbar-brand" href="/"><img src="/assets/images/logo.png" alt="Capecassia logo"/></a>
            </div>
            <div className="navbar-collapse collapse">
                <ul className="nav navbar-nav pull-right">
                    <li className="active"><a href="/">Home</a></li>
                    <li ><a href="/aboutMe">About me</a></li>
                    <li ><a href="/blog/other">Blog</a></li>
                    <li ><a href="/blog/Travel">Travel</a></li>
                    <li ><a href="/blog/Fashion">Fashion</a></li>
                    <li ><a href="/boutique">Boutique</a></li>
                  
                    {auth?<li><a className="btn" href="/dashboard">Dashboard</a></li>:null}
                    {auth?<li><a className="btn" onClick={onClick} href="/logout">SIGN OUT</a></li>:<div/>}
                </ul>
            </div>
        </div>
    </div> 
  );
};

export default NavigationBar;