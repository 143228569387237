import { 
   CHECKOUT_SESSION,
   STRIPE_LIST_PAINT,
   STRIPE_LIST_PRICES
 } from "../actions/types";
 
 const initialState = {
    sessionInfo:{},
    all_paints:{},
    all_prices:{}
 };
 
 export default function(state = initialState, action) {
    switch (action.type) {
       case CHECKOUT_SESSION :
          return {
             ...state,
             sessionInfo: action.payload
          };
      case STRIPE_LIST_PAINT :
         return {
            ...state,
            all_paints: action.payload
         };

      case STRIPE_LIST_PRICES:
         return {
            ...state,
            all_prices: action.payload
         }
      default:
         return state;
    }
 }