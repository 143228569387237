import React from "react";

const Cancel = () => (
  <header id="home" className="hero-area">    
  <div className="overlay">
      <span></span>
      <span></span>
  </div>
  <nav className="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar">
      <div className="container">
      <a href="index.html" className="navbar-brand"></a>       
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <i className="lni-menu"></i>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mr-auto w-100 justify-content-end">
            <li className="nav-item">
                <a className="nav-link page-scroll" href="/">Accueil</a>
            </li>
          </ul>
      </div>
      </div>
  </nav>  
  <div className="container">      
      <div className="row space-100">
      <div className="col-lg-6 col-md-12 col-xs-12">
          <div className="contents">
          <h2 className="head-title">Oops something went wrong!</h2>
            <p>Please retry the operation or contact us on hello@capecassia.com</p>
          </div>
      </div>
      <div className="col-lg-6 col-md-12 col-xs-12 p-0">
          <div className="intro-img">
            <img src="/assets/images/error_page.png" alt="Error page"/>
          </div>            
      </div>
      </div> 
  </div>             
</header>

);

export default Cancel;