import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Nav, Button, Container, Form} from "react-bootstrap";
import ListArticle from "./ListArticle";
import Gap from "../layout/Gap";
// import "./dashboard.scss";

const Dashboard = ({ articles, auth,list_img }) => {
   const inputRef = useRef(null);
   const [search, setSearch] = useState("");
   const [display, setDisplay] = useState(false);

   const handleChange = e => {
      setSearch(inputRef.current.value.toLowerCase());
   };

   useEffect(() => {
      setTimeout(() => {
         if (articles.length === 0) setDisplay(true);
      }, 1000);
   }, [articles]);

   const articles_exclude_about_me = articles.filter(a=>a.category !== "About me")

   return (
      <React.Fragment>
         <div className="container mx-3">
           <Gap/><Gap/>
            <div className="row">
               <div className="col-lg-12 pb-5">
                  <header className="page-header">
                        <h1 className="page-title"> Dashboard administration </h1>
                  </header>
           
                  <div className="row">
                     <div className="col-lg-12 pb-2">
                        <h4><span>💡 </span><b>Reminder : </b> <br/> <br/>
                           This dashboard allows you to update the articles in the blog section of Capecassia. You can either : 
                              <ul style={{paddingTop:"10px"}}> 
                                 <li > <b style={{background:'#F6C8EE'}}> <span>➕ </span>create a  new article</b> </li> 
                                 <li style={{paddingTop:"10px"}}> <b style={{background:'#B9F7B9'}}> <span>♻️ </span> update the About me section</b> </li> 
                                 <li style={{paddingTop:"10px"}}> <b style={{background:'#ADD8E6'}}>  <span>⚒️ </span>update your profil settings</b> </li> 
                              </ul>
                           <br/> <br/>
                           If you want to update a product in the Boutique, you have to connect to <a href="https://stripe.com/en-fr">Stripe. </a>If necessary, you can refer to <a href="https://support.stripe.com/questions/how-to-create-products-and-prices">Stripe help </a>to add a new product.</h4>
                     </div>
                     <br/>
                   
                  </div>
               </div>
            </div>
            <Gap/>
            <Nav className="justify-content-between mt-2">
               <div className="row">
                  <div className="col-lg-6 pb-5">
                     <Form>
                        <Form.Group controlId="searchBar">
                           <Form.Control
                              type="text"
                              placeholder="Search Articles..."
                              style={{ height: 40 }}
                              ref={inputRef}
                              onChange={handleChange}
                           />
                        </Form.Group>
                     </Form>
                  </div>

                  <div className="col-lg-2 pb-5">
                     <Link to="/dashboard/article/create">
                        <Button variant="light" className="styleBtn" style={{background:'#F6C8EE'}}>
                          <span>➕ </span> New article
                        </Button>
                     </Link>
                  </div>

                  <div className="col-lg-2 pb-5">
                     <Link to="/dashboard/introduction">
                        <Button variant="light" className="styleBtn" style={{background:'#B9F7B9'}}>
                           <span>♻️ </span>About me
                        </Button>
                     </Link>
                  </div>

                  <div className="col-lg-2 pb-5">
                     <Link to="/dashboard/settings">
                        <Button variant="light" className="styleBtn" style={{background:'#ADD8E6'}}>
                          <span>⚒️ </span> Settings
                        </Button>
                     </Link>
                  </div>
               </div> 
            </Nav>
         </div>
         <Gap/>
         {articles.length > 0 ? (

            <Container
               className="d-flex flex-column justify-content-center align-items-center"
            >
            <div className="row">
               <div className="col-lg-12 pb-5">
            
                     <ListArticle
                        articles={articles_exclude_about_me.filter(article =>
                           article.title.toLowerCase().includes(search)
                        )}

                        list_img={list_img}
                        type={1}
                     />
               </div>
            </div>
         </Container>
         ) : (
            display && (
               <Container
                  style={{ height: "50vh" }}
                  className="d-flex flex-column justify-content-center align-items-center"
               >
                  {" "}
                  <p className="text-secondary h3">No articles found !</p>
               </Container>
            )
         )}
      </React.Fragment>
   );
};

Dashboard.propTypes = {
   auth: PropTypes.bool.isRequired,
   articles: PropTypes.array.isRequired
};

export default Dashboard;