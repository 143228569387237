import { combineReducers } from "redux";
import registerReducer from "./registerReducers";
import errorReducer from "./errorReducers";
import articleReducer from "./articleReducers";
import authReducer from "./authReducers";
import toggleSubmit from "./messageReducers";
import stripeReducer from "./stripeReducers";


export default combineReducers({
   register: registerReducer,
   errors: errorReducer,
   article: articleReducer,
   auth: authReducer,
   toggle: toggleSubmit,
   stripe:stripeReducer
});