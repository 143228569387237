import React, { useEffect} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllArticles } from "../../actions/articleActions";
import WhoIam from "../../components/layout/WhoIam";

import { ContentState, EditorState, convertFromRaw } from "draft-js";


const WhoPage = ({

  getAllArticles,articles
})  => {

  useEffect(() => {
    getAllArticles();
 }, [getAllArticles]);


 const len_articles = [...Array(Object.keys(articles).length).keys()]
 const intro_article = len_articles.map(a=>Object.values(articles)[a]).filter(b=>b.category ==='About me')
 const intro_text = !intro_article[0]?EditorState.createWithContent(ContentState.createFromText('About me')):EditorState.createWithContent(convertFromRaw(JSON.parse(intro_article[0]["body"])))

  return (
    
      <WhoIam
        introduction={intro_article[0]}
        editorState={intro_text}
      />
    

  );
};

const mapStateToProps = state => ({
  articles: state.article.articles
});

WhoPage.propTypes = {
  articles: PropTypes.array.isRequired,
  getAllArticles: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  {getAllArticles}
)(WhoPage);