import React, { useState, useEffect} from "react";
import PropTypes from "prop-types";
import ArticleForm from "../../components/blog/ArticleForm";
import Validate from "../../components/form/Validate";
import { connect } from "react-redux";
import { createArticle } from "../../actions/articleActions";
import { EditorState,convertToRaw } from 'draft-js';

const CreateArticlePage = ({ 
   errors, createArticle,
   imageUpload,imgLoading,
   loading, article_categories,
   history
 }) => {

   const [article, setArticle] = useState({
         category:"Fashion",
         picture:"",
         title: "",
         body:{},
         errors: {}
    });
  
   useEffect(() => {
      setArticle(article => {
         return { ...article, errors };
      });
   },[
      errors
   ]);

   const [boolTitle,setBoolTitle]=useState(false)
   const [boolPic,setBoolPic]=useState(false)

   const steps = document.querySelectorAll('.stepper__row');
   const stepsArray = Array.from(steps);

   function clickHandler(target) {
      const currentStep = document.querySelector('.stepper__row--active');
      stateHandler(currentStep);
   }

   function stateHandler(step) {
   let nextStep;
   let currentStepIndex = stepsArray.indexOf(step);
   if (currentStepIndex < stepsArray.length - 1) {
      nextStep = stepsArray[currentStepIndex + 1];
      classHandler([nextStep, step])
   }
   }

   function classHandler(steps) {
      steps.forEach(step => {
         step.classList.toggle('stepper__row--disabled');
         step.classList.toggle('stepper__row--active');
      });
   
   }

   function boolTitleFunction(){
      clickHandler()
      setBoolTitle(!boolTitle)
   }

   const [editorState, setEditorState] = useState(EditorState.createEmpty())
   const [previewSource, setPreviewSource] = useState('')
   const [selectedFile,setSelectedFile]=useState('')

   const handleChange = e => {
      switch (e.target) {
         case undefined:
            setArticle({ ...article, body:e.blocks})
            break;
      
         default:
            switch (e.target.name) {
               case 'picture':
                  const file = e.target.files[0]
                  previewFile(file)
                  setSelectedFile(file)

                  const reader = new FileReader();

                  reader.readAsDataURL(file);
                  reader.onloadend = () => setArticle({...article,picture : reader.result})

                  break;
               case'body':
               setArticle({
                  ...article,
                  [e.target.name]: JSON.stringify(convertToRaw(editorState.getCurrentContent()))
               })
                  break;
               default:
                  setArticle({
                     ...article,
                     [e.target.name]: e.target.value
                  })
                  break;
            }
            break;
      }
   };

   const handleChangeContent = e =>{
      setEditorState(e);
   }
 
   const handleBlur = e => {
      const { name, value } = e.target;
      const error = { ...article.errors, ...Validate(name, value).errors };
      setArticle({ ...article, errors: { ...error } });
   };

   const previewFile = (file) => {
      const reader = new FileReader();
      let pic_size;
  
      reader.readAsDataURL(file);
      reader.onloadend = () => {
          setPreviewSource(reader.result);
       
      };

      Math.round(file.size/1000)>=2000 ?pic_size="Please upload a file smaller than 2 Mo":pic_size=""
      setArticle({...article,errors:{pic_size}})
  };

  const handleValidPicture = e => {
      e.preventDefault();
      clickHandler()
      setBoolPic(!boolPic)
   };

   const handleSubmit = () =>{    
   
      article.body = JSON.stringify(convertToRaw((editorState.getCurrentContent())))

      const { category, picture,title,body} = article;
      createArticle({ category, picture,title, body},history);
   }

   return (
         <ArticleForm
            loading={loading}
            imgLoading={imgLoading}
            article={article}
            onChange={handleChange}
            onBlur={handleBlur}
      
            onSubmit = {handleSubmit}
            editorState={editorState}
            list_cat={article_categories}
            onEditorStateChange={handleChangeContent}
            previewPicture={previewSource}
            titleForm={"Create an article"}
            isUpdate={false}
            isIntro={false}
            isNew={true}
            onClickHandler={clickHandler}

            //booleen for input
            boolTitle={boolTitle}
            boolTitleFunction={boolTitleFunction}
            boolPic={boolPic}
            boolPicFunction={handleValidPicture}

            //booleen check if picture is loaded in cloudinary
            picLoaded={imageUpload}
         />
   );
};

const mapStateToProps = state => ({
   loading: state.article.articleLoading,
   errors: state.errors,
   article_categories:state.article.article_categories,
   imgLoading:state.article.imgLoading,
   imageUpload:state.article.imageUpload
});

CreateArticlePage.propTypes = {
   createArticle: PropTypes.func.isRequired,
   errors: PropTypes.object.isRequired,
   loading: PropTypes.bool.isRequired,
   uploadImage:PropTypes.func.isRequired
};

export default connect(
   mapStateToProps,
   { 
      createArticle
   }
)(CreateArticlePage);