import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card, Button, Form, Container, Row, Col } from "react-bootstrap";
import Gap from "../layout/Gap";

import Input from "../form/Input";

const SignUp = ({ loading, client, onBlur, onChange, onSubmit }) => {
   const { user_name, email, password,errors} = client;

   return (

      <header id="header" className="header pt-5">
         <Gap/><Gap/>
         <Container className="pt-5">
            <Row>
               <Col className="mx-auto" sm={11} md={7} lg={5}>
                  <Card className="my-4">
                     <Form
                        noValidate
                        onSubmit={onSubmit}
                        className="p-sm-3 p-xs-1"
                     >
                        <Card.Body>
                           <Card.Title
                              className="text-center mb-4 mt-2 theme-color h3"
                           >
                              Création de profil
                           </Card.Title>
                           <Input
                              name="user_name"
                              type="text"
                              placeholder="Saisir un pseudo"
                              value={user_name}
                              onChange={onChange}
                              onBlur={onBlur}
                              text={{
                                 module: "SignUp",
                                 label: "Pseudo",
                                 error: errors.user_name
                              }}
                           />
                           <Input
                              name="email"
                              type="email"
                              placeholder="Entrer un email"
                              value={email}
                              onChange={onChange}
                              onBlur={onBlur}
                              text={{
                                 module: "SignUp",
                                 label: "Email",
                                 error: errors.email
                              }}
                           />
                           <Input
                              name="password"
                              type="password"
                              placeholder="Entrer un mot de passe"
                              value={password}
                              onBlur={onBlur}
                              onChange={onChange}
                              text={{
                                 module: "SignUp",
                                 label: "Password",
                                 error: errors.password
                              }}
                           />
                           <Button
                              variant="info"
                              type="submit"
                              className="mt-4"
                              disabled={loading}
                           >
                              Submit
                           </Button>
                           <Card.Text className="mt-2">
                              Already an account ?{" "}
                              <Link to={"/login"}>Connect</Link>.
                           </Card.Text>
                        </Card.Body>
                     </Form>
                  </Card>
               </Col>
            </Row>
         </Container>
         <Gap/><Gap/><Gap/>
      </header>
   );
};

SignUp.propTypes = {
   onSubmit: PropTypes.func.isRequired,
   onChange: PropTypes.func.isRequired,
   onBlur: PropTypes.func.isRequired,
   client: PropTypes.object.isRequired,
   loading: PropTypes.bool.isRequired
};

export default SignUp;