import React from "react";
import ListArticle from "../admin/ListArticle";
import PropTypes from "prop-types";
import Gap from "../layout/Gap";
// import "./blogArticle.scss";

const BlogListArticle = ({articles,titlePage}) => {

   return (
      <div>
          {/* <header id="head" className="secondary"></header> */}
          <Gap/>
            <div className="container">
                     <header className="page-header">
                           <h1 className="page-title">{titlePage==="other"?"Blog":titlePage}</h1>
                     </header>
                     <div className="row">
                        <div className="col-lg-12 pb-5">
                              <ListArticle
                                 articles={articles}
                                 type={0}
                              />
                        </div>
                     </div>
            </div>
      </div>
   );
};

BlogListArticle.propTypes = {
   articles: PropTypes.array.isRequired
};

export default BlogListArticle;