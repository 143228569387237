// customer actions
export const SET_ERRORS = "SET_ERRORS";
export const SET_REGISTER_USER = "SET_REGISTER_USER";
export const SET_MESSAGE_SUBMIT = "SET_MESSAGE_SUBMIT";
export const SET_MESSAGE_ERROR = "SET_MESSAGE_ERROR";

//auth actions
export const TOGGLE_ADMIN_LOADING = "TOGGLE_LOADING";
export const SET_CURRENT_ADMIN = "SET_CURRENT_ADMIN";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const GET_ADMIN_INFO="GET_ADMIN_INFO";
export const UPDATE_ADMIN_PROFIL=""

//article actions
export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const GET_ARTICLE = "GET_ARTICLE";
export const GET_ARTICLES = "GET_ARTICLES";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const TOGGLE_ARTICLE_LOADING = "TOGGLE_ARTICLE_LOADING";
export const TOGGLE_ARTICLES_LOADING = "TOGGLE_ARTICLES_LOADING";
export const RESET_ARTICLE = "RESET_ARTICLE";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const GET_IMG_CLOUDINARY="GET_IMG_CLOUDINARY";
export const DELETE_IMG = "DELETE_IMG";
export const TOGGLE_IMG_LOADING = "TOGGLE_IMG_LOADING";

//stripe actions
export const  CHECKOUT_SESSION = "CHECKOUT_SESSION";
export const STRIPE_LIST_PAINT = "STRIPE_LIST_PAINT";
export const STRIPE_LIST_PRICES = "STRIPE_LIST_PRICES";