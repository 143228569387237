import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerAdmin } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";

import Validate from "../../components/form/Validate";
import SignUp from "../../components/auth/SignUp";

const SignUpPage = ({ 
   history,registerAdmin, 
   auth, errors, clearErrors

   }) => {
   const [client, setClient] = useState({
      user_name: "",
      email: "",
      password: "",
      genre:"",
      birthdate:"",
      pays:"",
      errors: {}
   });

   useEffect(() => {
      const unlisten = history.listen(() => clearErrors());
      return () => unlisten();
   }, [history, clearErrors]);

   useEffect(() => {
      if (auth.isAuthenticated) history.push("/blog");
      setClient(client => {
         return { ...client, errors };
      });
   }, [errors, auth, history]);

   const handleChange = e => {
      setClient({
         ...client,
         [e.target.name]: e.target.value
      });
   };

   const handleBlur = e => {
      const { name, value } = e.target;
      const err = { ...client.errors, ...Validate(name, value).errors };
      setClient({ ...client, errors: { ...err } });
   };

   const handleSubmit = e => {
      e.preventDefault();
      const { user_name, email, password, genre, birthdate} = client;
      registerAdmin({ user_name, email, password,genre, birthdate}, history);
   };

   return (

      <div>

         <SignUp
            loading={auth.userLoading}
            client={{ ...client }}
            onBlur={handleBlur}
            onChange={handleChange}
            onSubmit={handleSubmit}
            
         />


         {/* </StepWizard> */}

      </div>
   );
};

SignUpPage.propTypes = {
   registerAdmin: PropTypes.func.isRequired,
   registerUserBridge:PropTypes.func.isRequired,
   clearErrors: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   errors: PropTypes.object.isRequired,

};

const mapStateToProps = state => ({
   auth: state.auth,
   errors: state.errors,

});

export default connect(
   mapStateToProps,
   {registerAdmin, clearErrors}
)(SignUpPage);