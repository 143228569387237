import React from "react";
import {Editor } from "draft-js";
import Gap from "../layout/Gap";


const Header = ({introduction,editorState})  => {
    return (
      <div>
   
    <Gap/>

       { !introduction?<div/>:
       
       
        <div className="container text-center">
                <div className="row">
                    <div className="col-sm-8">
                <br/>
                <h2 className="thin">{!introduction?null():introduction.title}</h2>

                {!introduction?null():
                    <Editor
                        name="body"           
                        editorState={editorState}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        wrapperStyle={{ border: "1px solid", marginBottom: "20px" }}
                        editorStyle={{ height: "300px", padding: "10px"}}
                    />
                    
                    }


                    </div>
                    
                    <div className="col-sm-4">
                    <Gap/>

                        <div id='who'></div>
                 
                    </div>
                    <div className="col-sm-6">
                        <img src="/assets/images/signature.png" style={{height:'130px',width:'auto'}} alt="signature"/>
                    </div>

                    
                </div>
            </div>
       
            
            }
    </div>
   
    );
};

export default Header;


