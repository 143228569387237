import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ArticleForm from "../../components/blog/ArticleForm";
import Validate from "../../components/form/Validate";
import { connect } from "react-redux";
import { getArticleByID, updateArticle } from "../../actions/articleActions";
import { EditorState, ContentState,convertFromRaw,convertToRaw} from 'draft-js';

const UpdateIntroPage = ({
   errors,updateArticle,
   loading,
   article_categories,
   articles,articleLoading,
   history
}) => {

   const len_articles = !articles?[0]:[...Array(Object.keys(articles).length).keys()]
   const view_intro =len_articles.map(a=>Object.values(articles)[a]).filter(b=>b.category==="About me")[0]
   const intro_text =!view_intro.body ?EditorState.createWithContent(ContentState.createFromText('Article')):EditorState.createWithContent(convertFromRaw(JSON.parse(view_intro.body)))

   const [article, setArticle] = useState({
      category:"About me",
      title: "About me",
      picture:"picture",
      body: intro_text,
      errors: {}
   });

   useEffect(()=>{
      setArticle(article => {
         return { ...article, errors };
      });
   },[errors])


   const [editorState, setEditorState] = useState(intro_text)

   const handleChange = e => {

      setArticle({...article,body: JSON.stringify(convertToRaw(editorState.getCurrentContent()))});
      
 
   };

   const handleChangeContent = e =>{
      setEditorState(e);
   }

   const handleBlur = e => {
      const { name, value } = e.target;
      const error = { ...article.errors, ...Validate(name, value).errors };
      setArticle({ ...article, errors: { ...error } });
   };

   const handleSubmit = e => {
      e.preventDefault();

      const{ category, picture,title,body } = article;
      const id = view_intro._id
  
      updateArticle( { id,category, picture,title,body }, history);
   };

   return (
      <ArticleForm
      loading={loading}
      article={article}
      onChange={handleChange}
      onBlur={handleBlur}

      onSubmit = {handleSubmit}
      editorState={editorState}
      list_cat={article_categories}
      onEditorStateChange={handleChangeContent}
      titleForm={"Update About me page"}
      isUpdate={true}
      isIntro={true}
      isNew={false}

      />
   )
};

const mapStateToProps = state => ({
   loading: state.article.articleLoading,
   article_categories:state.article.article_categories,
   errors: state.errors,
   articles: state.article.articles,
   articleLoading:state.article.articleLoading
});

UpdateIntroPage.propTypes = {
   loading: PropTypes.bool.isRequired,
   errors: PropTypes.object.isRequired,
   getArticleByID: PropTypes.func.isRequired,
   updateArticle: PropTypes.func.isRequired,
};

export default connect(
   mapStateToProps,
   {getArticleByID, updateArticle}
)(UpdateIntroPage);