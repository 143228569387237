import React from "react";
import { BrowserRouter, Route, Switch,Redirect} from "react-router-dom";
import { Provider } from "react-redux";

import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentAdmin, logoutAdmin } from "./actions/authActions";

import PrivateRoute from "./utils/PrivateRoute";

import Home from "./components/layout/Home";
import Footer from "./components/layout/Footer";
import NavigationBar from "./components/layout/NavigationBar"

import BlogPage from "./containers/blog/BlogPage";
import WhoPage from "./containers/layout/WhoPage";
import LoginPage from "./containers/auth/LoginPage";
import SignUpPage from "./containers/auth/SignUpPage";
import DashboardPage from "./containers/dashboard/DashboardPage";

import ViewArticlePage from "./containers/blog/ViewArticlePage";
import ViewArticleAdminPage from "./containers/admin/ViewArticleAdminPage";
import UpdateIntroPage from "./containers/blog/UpdateIntroPage";
import CreateArticlePage from "./containers/blog/CreateArticlePage";
import UpdateArticlePage from "./containers/admin/UpdateArticlePage";

import BoutiquePage from "./containers/boutique/BoutiquePage";
import ViewItemPage from "./containers/boutique/ViewItemPage";
import SettingsPage from "./containers/admin/SettingsPage";

import LegalMention from "./components/layout/LegalMention";

import SuccessPage from "./components/layout/Success";
import CancelPage from "./components/layout/Cancel";

if (localStorage.jwtToken) {
   const token = localStorage.jwtToken;
   setAuthToken(token);
   const decoded = jwt_decode(token);
   store.dispatch(setCurrentAdmin(decoded));
   const currentTime = Date.now() / 1000;
   if (decoded.exp < currentTime) {
      store.dispatch(logoutAdmin());
      window.location.href = "./login";
   }
}

export const handleClick = e => {
   e.preventDefault();
   store.dispatch(logoutAdmin())
};

const App = () => {
   return (
      <Provider store={store}>
         <BrowserRouter>
            {/* <ProgressBar /> */}
            <NavigationBar onClick={handleClick} auth={store.getState().auth.isAuthenticated}/>
               <Switch>
                  <Route path="/" exact component={Home} /> 
                  <Route path="/aboutMe" exact component={WhoPage}/> 
                  <Route path="/blog/:filter" exact component={BlogPage}/> 
                  <Route path="/blog/article/:linkFormat" exact component={ViewArticlePage} /> 

                  <Route path="/boutique" exact component={BoutiquePage}/> 
                  <Route path="/boutique/:titlePicture" exact component={ViewItemPage} />

                  <Route path="/login" exact component={LoginPage} /> 
                  <Route path="/signup" exact component={SignUpPage} />

                  <Route path="/success" exact component={SuccessPage}/>
                  <Route path="/cancel" exact component={CancelPage}/>

                  <PrivateRoute exact path="/dashboard" component={DashboardPage}/>
                  <PrivateRoute exact path="/dashboard/settings" component={SettingsPage}/>
                  
                  <PrivateRoute exact path="/dashboard/introduction" component={UpdateIntroPage}/>
                  <PrivateRoute exact path="/dashboard/article/create" component={CreateArticlePage}/>
                  <PrivateRoute exact path="/dashboard/article/:id" component={ViewArticleAdminPage} />
                  <PrivateRoute exact path="/dashboard/article/update/:id" component={UpdateArticlePage}/>

                  <Route path="/legalmentions" exact component={LegalMention}/>

                  <Redirect from="*" to="/" />
               </Switch>
               <Footer/>
         </BrowserRouter>
     
      </Provider>
   );
};

export default App;