import React, {useEffect } from "react";
import BoutiqueListItems from "../../components/boutique/BoutiqueListItems"
import {getAllPaints,getAllPrices, getCheckout} from "../../actions/stripeActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";


const BoutiquePage = ({
  getAllPaints,
  all_paints,
  getAllPrices,
  all_prices,
  getCheckout
})  => {

  useEffect(() => {
    getAllPaints();
    getAllPrices()
 }, [getAllPaints,getAllPrices]);

 const handleClick = (e,item,price) =>{
   e.preventDefault();
   let data = {item,price}
   getCheckout(data)
 }

  return (
    <BoutiqueListItems
      items={all_paints}
      prices={all_prices}
      onClick={handleClick}
    />
  );
};

const mapStateToProps = state => ({
  all_paints: state.stripe.all_paints,
  all_prices:state.stripe.all_prices
});

BoutiquePage.propTypes = {
  all_paints: PropTypes.array.isRequired,
  getAllPaints: PropTypes.func.isRequired,
  all_prices:PropTypes.array.isRequired,
  getAllPrices:PropTypes.func.isRequired,
  getCheckout:PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  {getAllPaints,getAllPrices,getCheckout}
)(BoutiquePage);