import React, {useEffect } from "react";
import BlogListArticle from "../../components/blog/BlogListArticle"
import {getAllArticles} from "../../actions/articleActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const BlogPage = ({
  getAllArticles,
  articles,
  match
})  => {

  useEffect(() => {
    getAllArticles();
 }, [getAllArticles]);

 let article_to_show;

 if(match.params.filter ==='other'){
  article_to_show = articles.filter(a=>a.category !=='About me').filter(b=> b.category !=="Travel").filter(c=>c.category !=="Fashion")
 }else{
  article_to_show = articles.filter(a=>a.category===match.params.filter)
 }

  return (

    <BlogListArticle
      articles={article_to_show}
      titlePage={match.params.filter}
    />
    
  );
};

const mapStateToProps = state => ({
  articles: state.article.articles
});

BlogPage.propTypes = {
  articles: PropTypes.array.isRequired,
  getAllArticles: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  {getAllArticles}
)(BlogPage);