import React from "react";
import PropTypes from "prop-types";
import Input from "../form/Input";
import Gap from "../layout/Gap";

import {
   Card,
   Button,
   Form,
   Container,
   Row,
   Col,
   Alert
} from "react-bootstrap";

const Login = ({ message, loading, client, onChange, onBlur, onSubmit }) => {
   const { email, password, errors } = client;
   return (
      <div>
         <Gap/>
         <Gap/>
       
         <Container className="pt-5 mt-5">
            <Row>
               <Col className="mx-auto pb-5" sm={11} md={7} lg={5}>
               <div className="border border-info border-right-0 border-left-0 pt-4 pb-5 text-center">
                  <Card className="my-4">
                     <Form
                        noValidate
                        onSubmit={onSubmit}
                        className="p-sm-3 p-xs-1"
                     >
                        <Card.Body>
                           <Card.Title
                              className="text-center theme-color mb-4 mt-2 h3"
                           >
                              Connexion
                           </Card.Title>
                           {message.length > 0 && (
                              <Alert variant="success">{message}</Alert>
                           )}
                           <Input
                              name="email"
                              type="email"
                              placeholder="Saisir l'email"
                              value={email}
                              onChange={onChange}
                              onBlur={onBlur}
                              text={{
                                 module: "login",
                                 label: "Email",
                                 error: errors.email
                              }}
                           />
                           <Input
                              name="password"
                              type="password"
                              placeholder="Saisir le mot de passe"
                              value={password}
                              onBlur={onBlur}
                              onChange={onChange}
                              text={{
                                 module: "login",
                                 label: "Mot de passe",
                                 error: errors.password
                              }}
                           />
                           <Button
                              variant="info"
                              type="submit"
                              className="mt-3"
                              disabled={loading}
                           >
                              Submit
                           </Button>
         
                        </Card.Body>
                     </Form>
                  </Card>
                  </div>
               </Col>
            </Row>
         </Container>

         <Gap/><Gap/><Gap/><Gap/>
      </div>

   );
};

Login.propTypes = {
   onSubmit: PropTypes.func.isRequired,
   onChange: PropTypes.func.isRequired,
   onBlur: PropTypes.func.isRequired,
   client: PropTypes.object.isRequired,
   message: PropTypes.string.isRequired,
   loading: PropTypes.bool.isRequired
};

export default Login;