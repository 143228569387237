import React, { useEffect } from "react";
import ViewArticle from "../../components/admin/ViewArticle";
import { deleteArticle, getArticleByID,getImagesCloudinary,deleteImgCloud } from "../../actions/articleActions";
import { ContentState, EditorState, convertFromRaw } from "draft-js";
import { connect } from "react-redux";
import PropTypes from "prop-types";


const ViewArticleAdminPage = ({
   auth,
   article,
   getImagesCloudinary,
   img_cloudinary,
   deleteImgCloud,
   match,
   history,
   getArticleByID,
   deleteArticle
}) => {
   useEffect(() => {
      getArticleByID(match.params.id);
      getImagesCloudinary();
    
   }, [match, getImagesCloudinary,getArticleByID],()=>img_cloudinary);

   const len_img = [...Array(Object.keys(img_cloudinary['result']).length).keys()]
   const img_tab = len_img.map(a => Object.values(img_cloudinary)[0][a])

   const len_articles = [...Array(Object.keys(article).length).keys()]
   const view_article = len_articles.map(a=>Object.values(article)[a])
   const article_text = !view_article[4]?EditorState.createWithContent(ContentState.createFromText('Article')):EditorState.createWithContent(convertFromRaw(JSON.parse(view_article[4])))
   
   const handleEdit = () => {
      history.push(`/dashboard/article/update/${article._id}`);
   };


   const handleDelete = () => {
      deleteImgCloud({public_id:img_tab.filter(a=>a.url ===article.picture).map(b=>b.public_id)[0]});
      deleteArticle(article._id,history);
   };

   if (Object.keys(article).length === 0) return <div />;
   return (
      <ViewArticle
         article={article}
         auth={auth}
         editorState={article_text}
         onDelete={handleDelete}
         onEdit={handleEdit}
      />
   );
};

const mapStateToProps = state => ({
   auth: state.auth.isAuthenticated,
   article: state.article.article,
   img_cloudinary:state.article.img_cloudinary
});

ViewArticleAdminPage.propTypes = {
   auth: PropTypes.bool.isRequired,
   article: PropTypes.object.isRequired,
   getArticleByID: PropTypes.func.isRequired,
   deleteArticle: PropTypes.func.isRequired,
   getImagesCloudinary:PropTypes.func.isRequired,
   deleteImgCloud:PropTypes.func.isRequired
};

export default connect(
   mapStateToProps,
   { getArticleByID, getImagesCloudinary,deleteArticle,deleteImgCloud}
)(ViewArticleAdminPage);