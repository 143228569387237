import React from "react";
import PropTypes from "prop-types";
import {Row, Col,Button } from "react-bootstrap";
import { Editor} from "draft-js";


const ViewArticle = ({ article, auth,onDelete,editorState,onEdit }) => {

   return (

      <div>
          <header id="head" className="secondary"></header>
         <div className="container">

            <div className="row">
               <article className="col-sm-12">
            
               <header className="page-header">
                     <h4 className="page-title"> 
                        <ul>
                           <li style={{listStyleType:'circle',display:'inline'}}><a href="/blog">Blog </a>● </li>
                           <li style={{listStyleType:'circle',display:'inline'}}>{article.category} ● </li>
                           <li style={{listStyleType:'circle',display:'inline'}}>{article.title}</li>
                        </ul>
                     </h4>
               </header>
     
               <img className="card-img center-block" src={article.picture} alt={article.title}/>
               </article>
            </div>

            <div className="row my4" style={{ whiteSpace: "pre-wrap" }}>

             <Col>
                  <Editor
                     name="body"
                     // value={article.body} 
                     editorState={editorState}
                     wrapperClassName="wrapper-class"
                     editorClassName="editor-class"
                     toolbarClassName="toolbar-class"
                     wrapperStyle={{ border: "1px solid", marginBottom: "20px" }}
                     editorStyle={{ height: "300px", padding: "10px"}}
                     // onEditorStateChange={onEditorStateChange}
                     // onChange={onChange}
                  />
             </Col>
              
            <Row className="d-flex flex-column font-italic footerStyle">
               <Col className='col-sm-10'>{article.author}</Col>
               <Col className='col-sm-2'>{article.date} </Col>
            </Row>
             {auth && (
                  <div>
                     <Row className="d-flex flex-column">
                        <Col className="col-sm-2">
                           <Button
                              className="text-center"
                              variant="outline-info"
                              onClick={onEdit}
                           >
                             🖋️ Edit
                           </Button>
                  
                        </Col>
                        <Col className="ml-3 col-sm-1">
                           <Button
                              className="text-center"
                              variant="outline-info"
                              onClick={onDelete}
                           >
                              ❌ Delete
                           </Button>
                        </Col>
                     </Row>
                    
                  </div>
               )}
     

            </div>
         </div>
      </div>
      
   );
};

ViewArticle.propTypes = {
   article: PropTypes.object.isRequired,
   auth: PropTypes.bool.isRequired,
   onEdit: PropTypes.func.isRequired,
   onDelete: PropTypes.func.isRequired
};

export default ViewArticle;